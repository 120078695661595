
import { Component, Vue, Prop } from 'vue-property-decorator';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const red5prosdk = require('./red5pro-sdk');

import { VsLoading } from '../../controls/vs-loading';
import { VsIcon } from '../../controls/vs-icon';
import { Participante } from './index';
import VsStreamingInit from './vs-streaming-init.vue';


@Component({ components: { VsLoading, VsIcon, VsStreamingInit } })
class VsSubscriber extends Vue {
    private mounted(){
        if(this.autoConnect) {
            this.subscribe();
        }
    }

    private beforeDestroy() {
        clearTimeout(this.reconectTimeout);
        this.finishVideoStreaming();
    }

    @Prop({ type: Boolean, default: true })
    private autoConnect!: boolean;
    
    @Prop({ type: Object, required: true })
    private stream!: Participante;

    @Prop({ type: String, default: 'edge.vsoft.com.br' })
    private host!: string;

    @Prop( { type: String, default: 'balanced' })
    private bundlePolicy!: string;

    @Prop({ type: Boolean, default: true })
    private autoReconnect!: boolean;

    @Prop({ type: Boolean, default: false })
    private showError!: boolean;

    @Prop({ type: Boolean, default: true })
    private showIllustration!: boolean;


    private loading: boolean = true;
    private error: string = '';
    private nTentativas: number = 0;
    private rtcSubscriber: any = null;
    private reconectTimeout: any = -1;

    public subscribe(): Promise<void> {
        return new Promise((resolve, reject) => {
            // Configurar
            if(this.stream.streamId.length > 0 ) {
                this.subscriberConfig.mediaElementId = 'subscriber-' + this.stream.streamId;
                this.subscriberConfig.streamName = this.stream.streamId;
                setTimeout(() => {
                    this.loading = true;
                    fetch(`https://${this.host}/api/v1/applications/live/streams?accessToken=vsoft`, { method: 'GET' })
                        .then(data => data.json())
                        .then((resp: {data: string[]}) => {
                            if(resp.data.includes(this.stream.streamId)){
                                const subscriberInstance = new red5prosdk.RTCSubscriber();
                                return subscriberInstance.init(this.subscriberConfig);
                            }
                            else {
                                throw `A stream ${this.stream.streamId} ainda n�o est� dispon�vel`;
                            }
                        })
                        .then((subscriberInit: any) => subscriberInit.subscribe())
                        .then((subscriber: any) => {
                            this.rtcSubscriber = subscriber;
                        
                            // Registrar eventos
                            this.rtcSubscriber.on('Subscribe.Connection.Closed', this.onConnectionClosed);

                            // Resetar erros
                            this.loading = false;
                            this.error = '';
                            this.nTentativas = 0;
                            const video = document.querySelector('#subscriber-' + this.stream.streamId) as HTMLVideoElement;

                            // Habilitar contro
                            const appleDevices = ['iPhone', 'iPod', 'iPad', 'Macintosh'];
                            appleDevices.forEach(el => {
                                if(navigator.platform.includes(el)) {
                                    video.controls = true;
                                }
                            });

                            setTimeout(() => {
                                this.$emit('onSubscribeStart', this.stream.streamId);
                                resolve();
                            }, 15000);
                        })
                        .catch((err: any) => {
                            this.$emit('onConnectionError', this.stream.streamId);
                            this.error = err;
                            if(!this.showError)
                                console.log(err);

                            this.reconectTimeout = setTimeout(() => {
                                if(this.autoReconnect) {
                                    const el = this.$refs[this.stream.streamId];
                                    if(el != null) {
                                        this.subscribe();
                                        this.nTentativas++;
                                    }
                                }
                            }, 10000);
                            reject(err);
                        });
                }, 4000);
            }
            else {
                reject('Stream name n�o informada');
                this.$emit('onSubscribeError', 'Stream name n�o informada');
            }
        });
    }

    private eventNameConvert(str: string)  {
        return 'on' + str.split(new RegExp('[.]', 'gi')).join('');
    }

    private onConnectionClosed(e: any) {
        if(e.type == 'Subscribe.Connection.Closed') {
            this.loading = true;
            this.error = 'Connection closed';

            if(!this.showError)
                console.log('Connection closed');

            if(this.rtcSubscriber != null) {
                this.rtcSubscriber.off('Subscribe.Connection.Closed', this.onConnectionClosed);
            }

            this.$emit('onConnectionError', this.stream.streamId);
            
            setTimeout(() => {
                this.subscribe();
            }, 15000);
        }
    }

    public finishVideoStreaming() {

        if(this.rtcSubscriber != null) {
            this.rtcSubscriber.unsubscribe();
        }

        const subscriber = (this.$refs[this.stream.streamId] as HTMLVideoElement);
        if(subscriber != null && subscriber.srcObject != null) {
            const stream = subscriber.srcObject as MediaStream;
            if(stream != null) {
                stream.getTracks().forEach(e => e.stop());
                subscriber.srcObject = null;
            }
        }
        
        this.rtcSubscriber = null;
        this.$emit('onStreamingFinished');
        this.loading = true;
    }

    private subscriberConfig = {
        protocol: 'wss',
        host: this.host,
        port: 443,
        app: 'live',
        mediaElementId: '',
        streamName: '',
        rtcConfiguration: {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
            iceCandidatePoolSize: 2,
            bundlePolicy: this.bundlePolicy
        }
    };


}

export default VsSubscriber;
