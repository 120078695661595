
import { Component, Vue } from 'vue-property-decorator';

@Component({ })
class VsToast extends Vue {
    private message: string = '';
    private timeout: any = -1;
    
    public showToast(message: string): void {

        this.message = message;
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            this.message = '';
        }, 3000);
    }
}

export default VsToast;
