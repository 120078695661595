
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { VsLoading } from '../../controls/vs-loading';
import { VsIcon } from '../../controls/vs-icon';
import { VsPublisherInstance } from '.';
import { Participante } from './index';
import VsStreamingInit from './vs-streaming-init.vue';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const red5prosdk = require('./red5pro-sdk');

@Component({ components: { VsLoading, VsIcon, VsStreamingInit } })
class VsPublisher extends Vue implements VsPublisherInstance {
    
    // ============= Ciclo de vida =============
    private mounted(){
        if(this.autoConnect) {
            this.publish();
        }
    }

    private beforeDestroy() {
        this.finishVideoStreaming();
    }

    // ============= Propriedades ===============
    @Prop({ type: Boolean, default: false })
    private autoConnect!: boolean;
    
    @Prop({ type: Object, required: true })
    private stream!: Participante;

    @Prop({ type: String, default: 'origin.vsoft.com.br' })
    private host!: string;

    @Prop( { type: String, default: 'balanced' })
    private bundlePolicy!: string;

    @Prop({ type: Boolean, default: true })
    private autoReconnect!: boolean;

    @Prop({ type: Boolean, default: false })
    private showError!: boolean;

    @Prop({ type: Number })
    private heightResolution?: number;

    @Prop({ type: Number })
    private widthResolution?: number;

    @Prop({ type: Boolean, default: true })
    private showIllustration!: boolean;

    // =============== Observadores ===================
    @Watch('stream.mic')
    private onMicrophoneChange(newValue: boolean){
        newValue ? this.rtcPublisher.unmuteAudio() : this.rtcPublisher.muteAudio();
    }
 
    @Watch('stream.cam')
    private onCameraChange(newValue: boolean) {
        newValue ? this.rtcPublisher.unmuteVideo() : this.rtcPublisher.muteVideo();
    }

    // =================== M�todos =============
    private checkStreamExist(): Promise<void> {
        return new Promise((resolve) => {
            fetch(`https://${this.host}/api/v1/applications/live/streams?accessToken=vsoft`, { method: 'GET' })
                .then(data => data.json())
                .then((resp: {data: string[]}) => {
                    if(resp.data.includes(this.stream.streamId)){
                        fetch(`https://${this.host}/api/v1/applications/live/streams/${this.stream.streamId}/action/unpublish?accessToken=vsoft`, { method: 'GET' });  
                    }
                })
                .finally(() => resolve());
        });
    }

    public publish(): Promise<void> {
        return new Promise((resolve, reject) => {
            if(this.stream.streamId.length > 0) {
                if(this.heightResolution && this.widthResolution) {
                    this.publisherConfig.mediaConstraints = {
                        audio: true,
                        video: {
                            width: {
                                max: this.widthResolution
                            },
                            height: {
                                max: this.heightResolution
                            }
                        }
                    };
                }
                this.publisherConfig.mediaElementId = 'publisher-' + this.stream.streamId;
                this.publisherConfig.streamName = this.stream.streamId; 
                setTimeout(() => {
                    this.loading = true;
                    const publisher = new red5prosdk.RTCPublisher();
                    publisher.init(this.publisherConfig)
                        .then((publisherInit: any) => {
                            this.timeoutPublisher = setTimeout(() => {
                                this.$emit('onPublishError', 'N�o conseguiu publicar');  
                            }, 30000);
                            return publisherInit.publish();
                        })
                        .then((publisher: any) => {
                            clearTimeout(this.timeoutPublisher);
                            this.rtcPublisher = publisher;
            
                            if(!this.stream.mic) {
                                this.rtcPublisher.muteAudio();
                            }
            
                            this.rtcPublisher.on('*', this.onConnectionClosed);
                            this.error = '';
                            this.nTentativas = 0;
                            this.loading = false;
            
                            setTimeout(() => {
                                resolve();
                                this.$emit('onPublishStart');
                            }, 1000);
                        })
                        .catch((err: any) => {
                            reject(err);
                            this.$emit('onPublishError', err);
                            if(!this.showError)
                                console.log(err);
                            this.error = err;
                            this.checkStreamExist();
            
                            // this.reconectTimeout = setTimeout(() => {
                            //     if(this.autoReconnect) {
                            //         const el = this.$refs[this.stream.socketId];
                            //         if(el != null) {
                            //             console.log('tentou reconectar');
                            //             this.publish();
                            //             this.nTentativas++;
                            //         }
                            //     }
                            // }, 10000);
                        });
                }, 1000);                
            }
            else {
                reject('Stream name n�o informada');
                this.$emit('onPublishError', 'Stream name n�o informada');
            }
        });
    }

    public finishVideoStreaming() {
        clearTimeout(this.reconectTimeout);

        if(this.rtcPublisher != null) {
            this.rtcPublisher.unpublish();
        }

        const publisher = (this.$refs[this.stream.streamId] as HTMLVideoElement);
        if(publisher != null && publisher.srcObject != null) {
            const stream = publisher.srcObject as MediaStream;
            if(stream != null) {
                stream.getTracks().forEach(e => e.stop());
                publisher.srcObject = null;
            }
        }
        this.rtcPublisher = null;
        this.$emit('onPublishFinished');
        this.loading = true;
    }
    // ================== Screen sharing Methods ===================
    public switchVideoToScreen(): Promise<void> {
        return new Promise((resolve, reject) => {
            (navigator.mediaDevices as any).getDisplayMedia({ audio: this.stream.mic, video: this.stream.cam })
                .then((stream: any) => {
                    stream.getTracks().forEach((track: any) => {
                        track.onended = () => {
                            if(track.kind === 'video'){
                                this.$emit('toogleScreenSharing');
                            }
                            track.stop();
                        };
                    });

                    if(this.stream.mic) {
                        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                            .then((micStream) => {
                                const tracks = [
                                    ...stream.getVideoTracks(),
                                    ...this.mergeAudioStreams(stream, micStream)
                                ];

                                this.swap(new MediaStream(tracks));
                                resolve();
                            })
                            .catch((error: any) => {
                                reject(error);
                            });
                    }
                    else {
                        this.swap(stream);
                        resolve();
                    }
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    public switchVideoToCamera(): Promise<void>{
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({ audio: this.stream.mic, video: this.stream.cam })
                .then((stream) => {
                    this.swap(stream);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                    console.error('N�o foi poss�vel realizar a mudan�a de camera: ' + error.message);
                });
        });
    }

    private mergeAudioStreams(desktopStream: MediaStream, voiceStream: MediaStream): MediaStreamTrack[]{
        const context = new AudioContext();
        const destination = context.createMediaStreamDestination();
        let hasDesktop = false;
        let hasVoice = false;
        if (desktopStream && desktopStream.getAudioTracks().length > 0) {
            // If you don't want to share Audio from the desktop it should still work with just the voice.
            const source1 = context.createMediaStreamSource(desktopStream);
            const desktopGain = context.createGain();
            desktopGain.gain.value = 0.7;
            source1.connect(desktopGain).connect(destination);
            hasDesktop = true;
        }
        
        if (voiceStream && voiceStream.getAudioTracks().length > 0) {
            const source2 = context.createMediaStreamSource(voiceStream);
            const voiceGain = context.createGain();
            voiceGain.gain.value = 0.7;
            source2.connect(voiceGain).connect(destination);
            hasVoice = true;
        }
        
        return (hasDesktop || hasVoice) ? destination.stream.getAudioTracks() : [];
    }

    private swap(stream: MediaStream) {

        const connection = this.rtcPublisher.getPeerConnection() as RTCPeerConnection;
        const senders = connection.getSenders();
        const tracks = stream.getTracks();

        /* Trocando a track de video */
        const videoSender = senders.find((s) => s.track != null && s.track.kind === 'video');
        const curVideoTrack = videoSender != null ? videoSender.track : null;
        const newVideoTrack = tracks.find((t) => t.kind === 'video');

        if (!newVideoTrack){
            console.error('N�o foi poss�vel realizar a mudan�a de camera: No video stream in connection');
            return;
        }

        if(curVideoTrack)
            curVideoTrack.stop();

        let replacePromise;   
        
        if(videoSender)
            replacePromise = videoSender.replaceTrack(newVideoTrack);

        /* Trocando a track de audio */

        const audioSender = senders.find((s) => s.track != null && s.track.kind === 'audio');
        const curAudioTrack = audioSender != null ? audioSender.track : null;
        const newAudioTrack = tracks.find((t) => t.kind === 'audio');

        if (audioSender && audioSender.track){
            if(curAudioTrack)
                curAudioTrack.stop();
            audioSender.replaceTrack(newAudioTrack ? newAudioTrack : null);
        }
        
        /* Atualizando a stream exibida localmente */

        (this.$refs[this.stream.streamId] as HTMLVideoElement).srcObject = stream;
        return replacePromise;
    }
    
    // ================= Eventos ======================
    private onConnectionClosed(e: any) {
        const errorEvents = ['Connect.Failure', 'Publish.Fail', 'Publish.InvalidName', 'Publisher.Connection.Closed'];

        if(errorEvents.includes(e.type)) {
            this.loading = true;
            this.error = 'Connection closed';
            if(!this.showError)
                console.log('Connection closed');

            if(this.rtcPublisher != null) {
                this.rtcPublisher.off('Publisher.Connection.Closed', this.onConnectionClosed);
            }
            this.$emit('onPublishError', 'Publisher.Connection.Closed');
        }
    }

    // ========== Propriedade reativas ===================

    private loading: boolean = true;
    private rtcPublisher: any = null;
    private error: string = '';
    private nTentativas: number = 0;
    private reconectTimeout: number = -1;
    private timeoutPublisher: any = null;

    // ============= Red 5 Config ===============
    private publisherConfig: any = {
        protocol: 'wss',
        host: this.host,
        port: 443,
        app: 'live',
        mediaElementId: '',
        streamName: '',
        rtcConfiguration: {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
            iceCandidatePoolSize: 2,
            bundlePolicy: this.bundlePolicy
        }
    };

   
}

export default VsPublisher;
